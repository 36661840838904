
import Vue from 'vue';
import utils from '../../../../util';
import RuleTypes from '../../../../types/validationRules';
import { Contacts, Contact } from '@point/utility-classes';
import { useLink } from '@c360/ui';

const validationRules = utils.ValidationRules;

interface GAEmailForm {
  yourName: string;
  yourEmail: string;
  ccEmail: string;
  advertiserName: string;
  advertiserEmail: string;
  subject: string;
}

interface GAEmailRequestOptions {
  landingpage: string;
  brandname: string;
  logo: string;
  repname: string;
  email: Array<string>;
  emailCc: Array<string>;
  subject: string;
  replyEmail: Array<string>;
  PropertyId: string;
  AgencyPartner: string;
  fromName: string;
}

const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export default Vue.extend({
  props: ['technicalContact', 'mediaContact', 'advertiser', 'ccEmailAddress'],
  name: 'linearAdminGAEmailContent',
  data: (): {
    validationRules: RuleTypes;
    formModel: GAEmailForm;
    error: string;
  } => ({
    validationRules,
    formModel: null,
    error: null,
  }),
  created(): void {
    this.formModel = {
      yourName: `${this.mediaContact.first_name} ${this.mediaContact.last_name}`,
      yourEmail: this.mediaContact.email,
      ccEmail: this.ccEmailAddress ? [this.ccEmailAddress] : null,
      advertiserName: this.technicalContact.first_name
        ? `${this.technicalContact.first_name} ${this.technicalContact.last_name}`
        : '',
      advertiserEmail: this.technicalContact.email ? [this.technicalContact.email] : null,
      subject: `Connecting to ${this.companyName || this.advertiser.AgencyPartner} Analytics (${this.advertiser.name})`,
    };
  },
  methods: {
    onChangeAdvertiserEmail(emailList): void {
      this.formModel.advertiserEmail = emailList.filter((email: string) => {
        return emailRegex.test(email);
      });
    },
    onChangeCCEmail(emailList): void {
      this.formModel.ccEmail = emailList.filter((email: string) => {
        return emailRegex.test(email);
      });
    },
    removeCCEmail(item: string): void {
      this.formModel.ccEmail.splice(this.formModel.ccEmail.indexOf(item), 1);
      this.formModel.ccEmail = [...this.formModel.ccEmail];
    },
    removeAdvertiserEmail(item: string): void {
      this.formModel.advertiserEmail.splice(this.formModel.advertiserEmail.indexOf(item), 1);
      this.formModel.advertiserEmail = [...this.formModel.advertiserEmail];
    },
    async sendEmail(): Promise<void> {
      // send the correct base domain
      // our google app works with 3 links:
      // prod: https://agency.analytics.adportal.io
      // dev: https://agency.analytics.dev.adportal.io
      // stg: https://agency.analytics.stg.adportal.io
      const origin = window.location.origin;
      let sub = '';
      if (origin.includes('dev.')) sub = 'dev.';
      else if (origin.includes('staging.')) sub = 'staging.';
      let sendURL = '';
      let siteName = '';
      sendURL = useLink({
        product: 'analytics',
        agency: 'agency',
        env: utils.getEnvForProductLink(),
      });
      siteName = utils.getAgencyFromURL();

      const options: GAEmailRequestOptions = {
        landingpage: `${sendURL}/grant-access/?aid=${this.advertiser.PropertyId}&site=${siteName}`,
        brandname: this.companyName || this.advertiser.AgencyPartner,
        logo: this.Theme?.name || this.advertiser.AgencyPartner,
        repname: this.formModel.yourName,
        email: this.formModel.advertiserEmail,
        emailCc: this.formModel.ccEmail,
        subject: this.formModel.subject,
        replyEmail: [this.formModel.yourEmail],
        PropertyId: this.advertiser.PropertyId,
        AgencyPartner: this.advertiser.AgencyPartner,
        fromName: this.formModel.advertiserName,
      };
      const resp = await this.$store.dispatch('linearAdmin/sendGAEmailDataAPI', options);
      if (resp.error) {
        this.hasError = true;
        this.error = resp.error;
        return;
      }
      this.$emit('close');
    },
  },
  computed: {
    emailPattern(): RegExp {
      return emailRegex;
    },
    valid(): boolean {
      if (!this.formModel.advertiserEmail || !this.formModel.advertiserEmail.length || !this.formModel.yourEmail)
        return false; // if missing emails
      if (!emailRegex.test(this.formModel.yourEmail.trim())) return false;
      return true;
    },
    contacts(): Contacts {
      return this.$store.state?.advertiser?.contacts || null;
    },
    allEmails(): string[] {
      const emails: string[] = [];

      for (const key in this.contacts) {
        if (this.contacts.hasOwnProperty(key)) {
          const contact: Contact = this.contacts[key];
          if (contact.email.length) {
            emails.push(contact.email);
          }
        }
      }
      return emails;
    },
    companyName(): string {
      let name = this.Theme?.companyName;
      if (name && (name === 'dev' || name === 'Demo')) {
        name = 'Sinclair';
      }
      if (name && (name === 'DriveAuto' || name === 'DealerAlchemist')) {
        // what do this do?
        name = name.replace(/([A-Z])/g, ' $1').trim();
      }
      return name;
    },
  },
});
